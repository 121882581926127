<template>
  <div v-document-title="``" v-meta-description="``">
    <h1 class="md-headline">Kategorie</h1>

    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-33 md-small-size-50 md-xsmall-size-100"
           v-for="set in sets" :key="set.id">
        <router-link :to="`/sets/${ set.id }/${ set.slug }/`">
          <md-card>
            <md-ripple>
              <md-card-header>
                <div class="md-title">{{ set.name }}</div>
                <div class="md-subhead">
                  {{ phrasesCount(set.id) }} fraz
                </div>
              </md-card-header>
              <div class="md-card-content">
                「{{ firstPhrase(set.id).japanese }}」…
              </div>
            </md-ripple>
          </md-card>
        </router-link>
      </div>

      <div class="md-layout-item md-size-33 md-small-size-50 md-xsmall-size-100">
        <router-link :to="`/sets/favorites/`" rel="nofollow">
          <md-card class="md-primary">
            <md-ripple>
              <md-card-header>
                <div class="md-title">Ulubione</div>
                <div class="md-subhead">
                  {{ favoritesCount }} fraz
                </div>
              </md-card-header>
              <div class="md-card-content">
                Użyj
                <md-icon style="color: currentColor;">favorite_outline</md-icon>
                i dodaj do ulubionych. Potem
                <md-icon style="color: currentColor;">filter_list</md-icon>
                filtruje ulubione w kategoriach.
              </div>
            </md-ripple>
          </md-card>
        </router-link>
      </div>
    </div>

    <h1 class="md-headline">Przewodniki</h1>

    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-33 md-small-size-50 md-xsmall-size-100">
        <a href="https://dojaponii.pl/"
           target="_blank" rel="noopener">
          <md-card>
            <md-ripple>
              <md-card-header>
                <div class="md-title">Rzeczy Do Japonii</div>
              </md-card-header>
              <div class="md-card-content">
                Rzeczy przydatne wszystkim podróżującym i planującym podróż do Japonii!
              </div>
            </md-ripple>
          </md-card>
        </a>
      </div>
    </div>

    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-33 md-small-size-50 md-xsmall-size-100">
        <a href="https://facebook.com/groups/japoniabudzetowo"
           target="_blank" rel="noopener">
          <md-card>
            <md-ripple>
              <md-card-header>
                <div class="md-title">Japonia budżetowo - tanie podróżowanie (Grupa Facebook)</div>
              </md-card-header>
              <div class="md-card-content">
                Największa polska społeczność podróżujących do Japonii.
                Prawie 6000 osób i mnóstwo inspiracji, nie tylko o tanim podróżowaniu!
              </div>
            </md-ripple>
          </md-card>
        </a>
      </div>
    </div>

    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-33 md-small-size-50 md-xsmall-size-100">
        <a href="https://btth.pl/"
           target="_blank" rel="noopener">
          <md-card>
            <md-ripple>
              <md-card-header>
                <div class="md-title">Byłem tu. Tony Halik</div>
              </md-card-header>
              <div class="md-card-content">
                Blog zawierający całą masę porad i inspiracji, w przeważającej większości poświęcony tematyce japońskiej!
              </div>
            </md-ripple>
          </md-card>
        </a>
      </div>
    </div>

    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-33 md-small-size-50 md-xsmall-size-100">
        <a href="https://ojaponii.pl/"
           target="_blank" rel="noopener">
          <md-card>
            <md-ripple>
              <md-card-header>
                <div class="md-title">Podcast o Japonii</div>
              </md-card-header>
              <div class="md-card-content">
                Pierwszy polski podcast o podróżach do Japonii i japońskiej kulturze!
              </div>
            </md-ripple>
          </md-card>
        </a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Sets',
  computed: {
    sets() {
      return this.$store.getters.sets;
    },
    favoritesCount() {
      return this.$store.getters.favoritesCount;
    },
  },
  methods: {
    phrasesCount(setId) {
      return this.$store.getters.phrasesCount(setId);
    },
    firstPhrase(setId) {
      return this.$store.getters.firstPhrase(setId);
    },
  },
};
</script>

<style scoped>
  .md-card {
    margin: 0 0 16px;
  }
</style>
