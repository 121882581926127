function install(Vue) {
  function setTitle(title) {
    if (title) {
      document.title = `${title} | Frazy Do Japonii`;
    } else {
      document.title = 'Frazy Do Japonii';
    }
  }

  function setDescription(text) {
    const element = document.querySelector('meta[name="description"]');
    const description = 'Frazy przydatne na podróż do Japonii, dla turystów, podróżników i sympatyków Kraju Kwitnącej Wiśni.  Dodaj tę stronę do ekranu głównego swojego telefonu, a stanie się apką.  Działa offline, zawiera Twoje ulubione frazy i podpowiada poprawną wymowę.';
    element.content = text || description;
  }

  Vue.directive('document-title', {
    inserted(el, binding) { setTitle(binding.value); },
    update(el, binding) { setTitle(binding.value); },
  });

  Vue.directive('meta-description', {
    inserted(el, binding) { setDescription(binding.value); },
    update(el, binding) { setDescription(binding.value); },
  });
}

export default {
  install,
};
